import React from "react";
import ClienteService from "../services/cliente.service";
import {numberToReal, uploadComprovantePix} from "../services/utils.service";
import * as dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat);


class MeusPedidosComponent extends React.Component {
    state = {
        loading:false,
        pedidosList:[]
    }

    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this)
        this.loadPedidos = this.loadPedidos.bind(this)
        this.parseDate = this.parseDate.bind(this)
        this.cancelarPedido = this.cancelarPedido.bind(this)
        this.onDocsFileChange = this.onDocsFileChange.bind(this)
        this.removerComprovantePix = this.removerComprovantePix.bind(this)

    }

    parseDate(dataDB){

        if(!dataDB)
            return null;
        return dayjs(dataDB).format('DD/MM/YYYY HH:mm')
    }

    componentDidMount() {
        this.loadPedidos()
    }

    loadPedidos(){
        this.setState({loading:true})

        ClienteService.getPedidos(this.state.limit,this.state.offsset).then(pedidosPage=>{
            console.log(pedidosPage)
            this.state.pedidosList = pedidosPage.rows;
        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    navigateTo(path){

        alert(path)
    }

    async cancelarPedido(pedido){
        if(window.confirm('Realmente deseja cancelar o pedido?\r\n Ação não de ser desfeita')){

          await ClienteService.cancelarPedido(pedido)
            this.loadPedidos()
            window.toastr.success('Pedido cancelado com sucesso!')

        }
    }


    removerComprovantePix(pedido){

        ClienteService.removerComprovantePix(pedido).then(()=>{
            window.toastr.success('Pedido atualizado')
            this.loadPedidos()
        })
    }

    onDocsFileChange(inputFile,pedidoId){

        let inputFileElement = inputFile;

        for(let idx=0;idx < inputFileElement.files.length;idx++){
            const reader = new FileReader();
            reader.readAsDataURL(inputFileElement.files[idx]);

            const fileSize = inputFileElement.files[idx].size;
            let filename = inputFileElement.files[idx].name;
            let extensao = filename.toLowerCase();
            extensao = extensao.substring(extensao.indexOf('.'));

            let doc = {
                nome : filename,
                extensao : extensao,
                size : fileSize,
                uploading:true,
                imageBase64:null,
                progress:10.0,
                pedidoId:pedidoId.toString()
            }

            reader.onload = () => {

                // doc.imageBase64 = reader.result
                // this.galeriaModel.imagens.push(doc)
                uploadComprovantePix(inputFileElement.files[idx],doc,(progress)=>{
                    console.log(progress)
                }).then((doc)=>{
                    this.loadPedidos()
                }).catch((err)=>{

                    console.error(err)
                })

            };
            reader.onerror = error => {
                console.error(error)
            };
        }

    }


    render() {

        return (
            <div className="product-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-4">
                            <div className="account-form">
                                <h4 className=" mb-30">Meus pedidos</h4>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <td>
                                        <strong>Nº pedido / Status</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            Descrição de itens
                                        </strong>
                                    </td>
                                    <td>
                                        <strong>Valor</strong>
                                    </td>
                                    <td>
                                        <strong>Ações</strong>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.loading===false && this.state.pedidosList && this.state.pedidosList.length===0 && (
                                <tr>
                                    <td className='text-center bg-info' colSpan={5}>
                                        Sem pedidos realizados
                                    </td>
                                </tr>

                            )}
                            {this.state.loading && (
                                <tr>
                                    <td colSpan={5}>
                                        {this.state.loading?(
                                            <div className="mt-30 text-center">
                                                <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                            </div>
                                        ):null}
                                    </td>
                                </tr>

                            )}
                            {this.state.pedidosList && this.state.pedidosList.length>0 &&
                                this.state.pedidosList.map((pedido,idx)=>(
                                    <tr key={idx}>
                                        <td>
                                            <div>
                                                Data: {this.parseDate(pedido.createdAt)}
                                            </div>
                                            <div>
                                                {/*Nº pedido: {pedido.identificador}*/}
                                            </div>
                                            <div>
                                                Status: <strong>{pedido.statusPedido.nome}</strong>
                                                {
                                                    pedido.comprovantePix && (
                                                        <div className='mt-20'>
                                                            <a href={pedido.comprovantePix.rawUrl} target='_blank'>
                                                                <i className='fa fa-file-archive-o'/>
                                                                comprovante pix
                                                            </a>

                                                            <a className='text-danger ml-30 clickable' onClick={()=>this.removerComprovantePix(pedido)}>
                                                                <i className='fa fa-times fa-2x text-danger ' title='remover anexo' />
                                                            </a>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </td>
                                        <td>

                                            {
                                                pedido.ItemPedidos.map((itemPedido,idx)=>(

                                                    <div key={idx} className='mb-20'>
                                                        <div>

                                                            <div>
                                                                {itemPedido.ProdutoEstoque.Produto.nome}
                                                                <small className='ml-10'>
                                                                    {itemPedido.ProdutoEstoque.tamanho &&
                                                                        <span className='ml-10'>Tam.: {itemPedido.ProdutoEstoque.tamanho}</span>}

                                                                    {itemPedido.ProdutoEstoque.membro && <span className='ml-10'>
                                                                    Membro: {itemPedido.ProdutoEstoque.membro} </span>}

                                                                    {itemPedido.ProdutoEstoque.ProdutoCor && <span className='ml-10'>
                                                                    Cor: {itemPedido.ProdutoEstoque.ProdutoCor.nome} </span>}


                                                                </small>
                                                            </div>
                                                            <div>
                                                                <span className='mr-15'>
                                                                    <strong>Valor:</strong> R$ {numberToReal(itemPedido.quantidade*itemPedido.ProdutoEstoque.Produto.preco)}
                                                                </span>
                                                                  <span>
                                                                      <strong>Quantidade:</strong> {itemPedido.quantidade}
                                                                  </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </td>
                                        <td>
                                            <div>
                                                Valor itens: R$ {numberToReal(pedido.valorTotalItens)}
                                            </div>
                                            <div>
                                                Frete: R$ {numberToReal(pedido.frete)}
                                            </div>
                                            <hr/>
                                            <div>
                                                Total: R$ {numberToReal(pedido.valorTotalPedido)}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                {
                                                    pedido.statusPedido.key==='AGUARDANDO_PAGAMENTO_GATEWAY' &&
                                                    <button className='btn btn-danger btn-sm' onClick={()=> this.cancelarPedido(pedido)}>Cancelar pedido</button>
                                                }
                                                {
                                                    pedido.statusPedido.key==='AGUARDANDO_CONFIRMACAO_PIX' && !pedido.comprovantePixId && (
                                                        <>
                                                            <button className='btn btn-info btn-sm' onClick={()=> document.getElementById('comprovantePixInput').click()} >Adicionar comprovante</button>
                                                            <input  id='comprovantePixInput' type='file' style={{width:0,height:0}}  accept=".jpg,.JPEG,.png" onChange={(event)=>{
                                                                this.onDocsFileChange(event.target,pedido.id)
                                                            }} />
                                                        </>
                                                    )

                                                }
                                            </div>

                                        </td>
                                    </tr>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default MeusPedidosComponent
