import React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {navigate} from "gatsby";

class MinhaContaComponent extends React.Component {
    state = {
        user:{
            username: null,
            password: null,
        },
        loading:false,
    }

    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this)

    }

    navigateTo(path){
        navigate(path)
    }

    render() {

        return (
            <div className="product-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-4">
                            <div className="account-form">
                                <h4 className=" mb-30">Minha conta</h4>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 clickable" onClick={event=> this.navigateTo('/app/meus-dados')}>
                            <figure className="figure">
                                <StaticImage
                                    src="../images/undraw_personal_file.png"
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt="Meus dados"
                                    style={{ marginBottom: `1.45rem` }}
                                />
                                <figcaption className="text-center figure-caption">Meus dados</figcaption>
                            </figure>
                        </div>
                        <div className="col-md-4 clickable" onClick={event=> this.navigateTo('/app/meus-enderecos')}>
                                <figure className="figure">
                                    <StaticImage
                                        src="../images/undraw_home_octe.png"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        alt="Meus Endereços"
                                        style={{ marginBottom: `1.45rem` }}
                                    />
                                        <figcaption className="text-center figure-caption">Meus endereços</figcaption>
                                </figure>
                        </div>
                        <div className="col-md-4 clickable" onClick={event=> this.navigateTo('/app/meus-pedidos')}>
                                <figure className="figure">
                                    <StaticImage
                                        src="../images/undraw_order_delivered.png"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        alt="Meus pedidos"
                                        style={{ marginBottom: `1.45rem` }}
                                    />
                                        <figcaption className="text-center figure-caption">Meus pedidos</figcaption>
                                </figure>
                        </div>
                        <div className="col-md-4 clickable" onClick={event=> this.navigateTo('/app/mudar-senha')}>
                                <figure className="figure">
                                    <StaticImage
                                        src="../images/undraw_security.png"
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                        alt="Mudar senha"
                                        style={{ marginBottom: `1.45rem` }}
                                    />
                                        <figcaption className="text-center figure-caption">Mudar senha</figcaption>
                                </figure>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MinhaContaComponent
