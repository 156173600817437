import React from "react"
import {Link, navigate} from "gatsby"
import {handleLogin, isLoggedIn, updateUserPassword} from "../services/auth"
import * as UtilService from '../services/utils.service';

class MudarSenhaComponent extends React.Component {
    state = {
        showPassword:false,
        user:{
            password: null,
            newPassword: null,
        },
        loading:false,
    }

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
    }

    handleUpdate(event) {
        let userChanges = {
            [event.target.name]: event.target.value,
        };
        userChanges = Object.assign(this.state.user,userChanges,);
        this.setState({user:userChanges})

    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading:true})

        let data = {...this.state.user};

            updateUserPassword(data.password,data.newPassword).then(()=>{
                navigate(`/app/minha-conta`)
                alert('Senha atualizada com sucesso!')
            }).catch(err=>{
                if(err.status && err.json){
                    err.json().then(body=>{

                        if(err.status===400 && body.message){
                            alert(body.message)
                        }
                    })
                }else{
                    alert('Erro ao acessar serviço. Tente mais tarde')

                }
                this.setState({loading:false})

            })
    }

    render() {
        return (
            <div className="product-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-4">
                            <ol className="breadcrumb">
                                <li><Link to="/app/minha-conta">Minha conta</Link></li>
                                <li>Mudar senha</li>
                            </ol>
                        </div>
                        <div className="col-sm-4 col-sm-offset-4" style={{marginTop:'20px'}}>
                            <div className="account-form">
                                <h4 className="text-center mb-30">Informe sua senha e a nova senha</h4>
                                <form method="post" onSubmit={event => {
                                    this.handleSubmit(event)
                                }}>
                                    <fieldset disabled={this.state.loading}>
                                        <div className="form-group">
                                            <label className="sr-only">Senha <span className="text-danger">*</span></label>
                                            <input type={this.state.showPassword ? "text" : "password"} className="form-control input-lg" placeholder="Senha atual" name="password" onChange={this.handleUpdate}/>

                                        </div>

                                        <div className="form-group">
                                            <label className="sr-only">Nova senha <span className="text-danger">*</span></label>
                                            <input type={this.state.showPassword ? "text" : "password"} className="form-control input-lg" placeholder="Nova senha" name="newPassword" onChange={this.handleUpdate}/>
                                        </div>

                                        <div className="text-center clickable" style={{marginBottom:'10px'}}>
                                            <a onClick={
                                                event=> this.setState({showPassword:!this.state.showPassword})
                                            } >
                                                {this.state.showPassword ? (
                                                    <span>Esconder senha <i className="fa fa-eye-slash " /></span>
                                                ):(
                                                    <span>Exibir senha <i className="fa fa-eye " /></span>
                                                )}
                                            </a>
                                        </div>
                                        <div className="form-group">
                                            <button disabled={!this.state.user.newPassword || !this.state.user.password} type="submit" className="btn btn-block btn-round btn-base" >Alterar senha</button>
                                        </div>

                                        {this.state.loading?(
                                                <div className="mt-30 text-center">
                                                    <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                </div>
                                            ):
                                            null }

                                    </fieldset>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MudarSenhaComponent
