import React from "react";
import {maskCep, maskDate, maskPhone} from "./mask-str";
import {Link, navigate} from "gatsby";
import {getCliente, register} from "../services/auth";
import * as dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import {validarCNPJ, validarCPF} from "../services/utils.service";
import ClienteService from "../services/cliente.service";

dayjs.extend(customParseFormat);

class MeusDadosComponent extends React.Component {
    state = {
        loading:false,
        fieldsWithError:{},
        formisValid:false,
        user:{
            "cpfCnpj": '',
            "email":'',
            "password":''
        },
        cliente:{
            id:'',
            "cpfCnpj": '',
            "nomeCompleto": '',
            "email":'',
            "genero":'',
            "telefoneCelular":'',
            "isWhatsapp":'',
            "dataNascimento":'',
            "assinaNewsletter":false
        },
    }

    constructor(props) {
        super(props);

        this.handleCliente = this.handleCliente.bind(this)
        this.isFieldValid = this.isFieldValid.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.atualizarDadosCliente = this.atualizarDadosCliente.bind(this)



    }

    componentDidMount() {
        let cliente = getCliente();

        this.state.cliente = Object.assign({},cliente);
        if(this.state.cliente.dataNascimento){
            this.state.cliente.dataNascimento = dayjs(this.state.cliente.dataNascimento).format('DD/MM/YYYY')
        }
        this.setState({refresh:true})
    }

    handleCliente(event,formmatter) {
        let target = event.target;

        let userChanges = null;

        if(target.type==='checkbox'){
            userChanges = {
                [target.name]: target.checked,
            };
        }else{


            let value = target.value;
            if(value && formmatter){
                value = formmatter?formmatter(target.value.trim()):target.value.trim();
            }
            userChanges = {
                [target.name]: value,
            };
        }

        userChanges = Object.assign(this.state.cliente,userChanges);
        if(this.state.fieldsWithError[target.name]){
            let fieldValid = this.isFieldValid(target);
            if(fieldValid){
                this.state.fieldsWithError[target.name] = false;
                delete this.state.fieldsWithError[target.name]
            }
        }
        this.setState({cliente:userChanges})

    }


    validateForm(){
        this.setState({
            loading: true
        });
        this.state.fieldsWithError={}
        var fieldsToValidateList = Array.from(document.getElementsByClassName('form-field'))
        fieldsToValidateList.forEach(field=>{
            if(this.isFieldValid(field)===false){
                this.state.fieldsWithError[field.name] = true;
            }
        })

        this.state.formisValid = Object.keys(this.state.fieldsWithError).length===0;
        this.setState({
            loading: false
        });
    }

    isFieldValid(target){

        let result = true;
        let validationMap ={
            cpfCnpj:()=>{
                if(target.value.length===11){
                    result = validarCPF(target.value);
                }else if(target.value.length===14){
                    result = validarCNPJ(target.value);
                }else{
                    result = false;
                }
            },
            cep:()=>{
                result = target.value.replace(/\D/g,'').length===8  ;
            },
            dataNascimento:()=>{
                let dataNascimento = dayjs(target.value,'DD/MM/YYYY');
                result = dataNascimento.isValid()

            },
            password:()=>{
                result = target.value.length>=4  ;
            },
        }

        if(target.required){

            if((target.value==='' || !!target.value===false )){
                result = false;
            }else{
                validationMap[target.name] && validationMap[target.name]();
            }

        }

        return result

    }

    atualizarDadosCliente(){

        this.validateForm()
        if(this.state.formisValid){
            this.setState({
                loading: true
            });
            let formData = {
                cliente:Object.assign({},this.state.cliente),
            }

            formData.cliente.cpfCnpj = formData.cliente.cpfCnpj.trim().replace(/[^\d]+/g, '');
            formData.cliente.telefoneCelular = formData.cliente.telefoneCelular.trim().replace(/[^\d]+/g, '');

            let dayjs1 = dayjs(formData.cliente.dataNascimento,'DD/MM/YYYY');
            let dataNascimento = dayjs1.format('YYYY-MM-DD');
            formData.cliente.dataNascimento = dataNascimento;

            ClienteService.update(formData.cliente).then(()=>{
                navigate(`/app/minha-conta`)
                alert('Conta atualizada')
            }).catch(err=>{
                if(err.status && err.json){
                    err.json().then(body=>{

                        if(err.status===400 && body.message){
                            alert(body.message)
                        }
                    })
                }else{
                    console.error(err)
                    alert('Erro ao acessar serviço. Tente mais tarde')

                }
            }).finally(()=>{
                this.setState({
                    loading: false
                });
            })
        }

    }

    render() {

        return (
            <div className="product-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-sm-offset-4">
                            <div className="account-form">
                                {/*<h4 className=" mb-30">Meus dados</h4>*/}
                                <ol className="breadcrumb">
                                    <li><Link to="/app/minha-conta">Minha conta</Link></li>
                                    <li>Meus dados</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                    <div className="product-area ptb-90">
                        <div className="">
                            <div className="row">
                                <div className="col-sm-6 col-sm-offset-3">
                                    <div className="account-form">
                                        <h4 className="text-center mb-30">Atualizar sua conta</h4>
                                        <form method="post">
                                            <fieldset disabled={this.state.loading}>
                                                <div className="row">
                                                    <div className={`col-md-6 form-group ${this.state.fieldsWithError['nomeCompleto']?'has-error':''}`}>
                                                        <label className="">Nome completo <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control input-lg form-field" name="nomeCompleto" id="nomeCompleto" required
                                                               value={this.state.cliente.nomeCompleto}
                                                               onChange={this.handleCliente}
                                                               placeholder="Nome completo" />
                                                    </div>

                                                    <div className={`col-md-6 form-group ${this.state.fieldsWithError['cpfCnpj']?'has-error':''}`}>
                                                        <label className="">CPF / CNPJ <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control input-lg form-field" required
                                                               value={this.state.cliente.cpfCnpj}
                                                               name="cpfCnpj" onChange={this.handleCliente}
                                                               placeholder="" />
                                                    </div>

                                                    <div className={`col-md-12 form-group ${this.state.fieldsWithError['email']?'has-error':''}`}>
                                                        <label className="">E-mail <span className="text-danger">*</span> <small>Necessário para recuperação de senha</small> </label>
                                                        <input type="email" className="form-control input-lg form-field" required
                                                               name="email" onChange={this.handleCliente}
                                                               value={this.state.cliente.email}
                                                               placeholder="" />
                                                        <label className="mt15px">
                                                            <span className="mr15px">Assinar newsletter</span>

                                                            <input type="checkbox" checked={this.state.cliente.assinaNewsletter} name="assinaNewsletter" onChange={this.handleCliente} />
                                                        </label>

                                                    </div>

                                                    <div className={`col-md-12 form-group ${this.state.fieldsWithError['telefoneCelular']?'has-error':''}`}>
                                                        <label className="">Telefone celular <span className="text-danger">*</span> <small>Necessário para atualização de pedidos</small> </label>
                                                        <input type="text" className="form-control input-lg form-field" required
                                                               name="telefoneCelular"
                                                               value={this.state.cliente.telefoneCelular?maskPhone(this.state.cliente.telefoneCelular):null}
                                                               onChange={(event)=> this.handleCliente(event,maskPhone)}
                                                               placeholder="" />
                                                        <label className="mt15px">
                                                            <span className="mr15px">whatsapp</span>
                                                            <input type="checkbox" checked={this.state.cliente.isWhatsapp} name="isWhatsapp" onChange={this.handleCliente} />
                                                        </label>
                                                    </div>

                                                    <div className={`col-md-12 form-group ${this.state.fieldsWithError['dataNascimento']?'has-error':''}`}>
                                                        <label className="">Data nascimento</label>
                                                        <input type="text" className="form-control input-lg form-field" maxLength={10}
                                                               value={this.state.cliente.dataNascimento}
                                                               name="dataNascimento"
                                                               onChange={(event)=> this.handleCliente(event,maskDate)}
                                                               placeholder="" />
                                                    </div>


                                                    {this.state.loading?(
                                                        <div className="mt-30 text-center">
                                                            <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                        </div>
                                                    ):null}

                                                    <div className="col-md-12 form-group">
                                                        <button type='button' className="btn btn-block btn-round btn-base" onClick={this.atualizarDadosCliente} >Atualizar dados</button>
                                                        <div style={{marginTop: 30 + 'px'}} className="text-center">
                                                            <Link  className="clickable" to="/app/minha-conta" > cancelar</Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </fieldset>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MeusDadosComponent
