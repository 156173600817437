import * as React from "react"
import Layout from "../components/layout";
import {Router} from "@reach/router";
import MinhaContaComponent from "../components/minha-conta";
import MeusDadosComponent from "../components/meus-dados";
import MeusEnderecosComponent from "../components/meus-enderecos";
import MeusPedidosComponent from "../components/meus-pedidos";
import MudarSenhaComponent from "../components/mudar-senha";


const App = () => (
    <Layout>
        <Router>
            <MinhaContaComponent path="/app/minha-conta" />
            <MeusDadosComponent path="/app/meus-dados" />
            <MeusEnderecosComponent path="/app/meus-enderecos" />
            <MeusPedidosComponent path="/app/meus-pedidos" />
            <MudarSenhaComponent path="/app/mudar-senha" />
        </Router>
    </Layout>
)

export default App
