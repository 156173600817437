import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link, navigate} from "gatsby";
import * as CepService from "../services/cep.service";
import ClienteService from "../services/cliente.service";
import {maskCep} from "./mask-str";
import {validarCNPJ, validarCPF} from "../services/utils.service";
import * as dayjs from "dayjs";

const LISTING_VIEW='LISTING_VIEW';
const FORM_VIEW='FORM_VIEW';


class MeusEnderecosComponent extends React.Component {
    state = {
        view:LISTING_VIEW,
        fieldsWithError:{},
        formisValid:false,
        endereco:{
            id:null,
            apelido:'',
            cep:'',
            logradouro:'',
            complemento:'',
            bairro:'',
            localidade:'',
            uf:'',
            numero:'',
            principal:false
        },
        enderecoList:[],
        loading:false,
    }

    constructor(props) {
        super(props);

        this.handleEndereco = this.handleEndereco.bind(this)
        this.searchCepEndereco = this.searchCepEndereco.bind(this)
        this.loadEnderecos = this.loadEnderecos.bind(this)
        this.salvarEndereco = this.salvarEndereco.bind(this)
        this.navigateTo = this.navigateTo.bind(this)
        this.novoEndereco = this.novoEndereco.bind(this)
        this.editarEndereco = this.editarEndereco.bind(this)
        this.excluirEndereco = this.excluirEndereco.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.isFieldValid = this.isFieldValid.bind(this)

    }

    componentDidMount() {
        this.loadEnderecos();

    }

    navigateTo(path){
        navigate(path)
    }

    handleEndereco(event,formatter) {
        let target = event.target;

        let userChanges = null;

        if(target.type==='checkbox'){
            userChanges = {
                [target.name]: target.checked,
            };
        }else{
            let value = target.value;
            if(value && formatter){
                value = formatter(target.value.trim());
            }
            userChanges = {
                [target.name]: value,
            };
        }

        userChanges = Object.assign(this.state.endereco,userChanges);
        if(this.state.fieldsWithError[target.name]){
            let fieldValid = this.isFieldValid(target);
            if(fieldValid){
                this.state.fieldsWithError[target.name] = false;
                delete this.state.fieldsWithError[target.name]
            }
        }
        this.setState({endereco:userChanges})


    }

    searchCepEndereco(event){
        this.setState({
            loading: true
        });

        CepService.loadCep(this.state.endereco.cep).then(enderecoViaCep=>{

            /*
            "cep": "66813-750",
              "logradouro": "Travessa N-5",
              "complemento": "(Cj COHAB)",
              "bairro": "Campina de Icoaraci (Icoaraci)",
              "localidade": "Belém",
              "uf": "PA",
              "ibge": "1501402",
              "gia": "",
              "ddd": "91",
              "siafi": "0427"
             */
            this.state.endereco.logradouro = enderecoViaCep.logradouro;
            this.state.endereco.complemento = enderecoViaCep.complemento;
            this.state.endereco.bairro = enderecoViaCep.bairro;
            this.state.endereco.localidade = enderecoViaCep.localidade;
            this.state.endereco.uf = enderecoViaCep.uf;

            delete this.state.fieldsWithError['logradouro'];
            delete this.state.fieldsWithError['complemento'];
            delete this.state.fieldsWithError['bairro'];
            delete this.state.fieldsWithError['localidade'];
            delete this.state.fieldsWithError['uf'];

            // console.log(enderecoViaCep)

        }).catch(err=>{
            console.error(err)
        }).finally(()=>{
            this.setState({
                loading: false
            });
        })


    }


    loadEnderecos(){

        this.setState({loading:true})

        ClienteService.getEnderecos().then(enderecoList=>{
            this.state.enderecoList = enderecoList
            console.log(enderecoList);

        }).finally(()=>{
            this.setState({loading:false})
        })

    }

    novoEndereco(){
        this.setState({
            view:FORM_VIEW,
            endereco:{
            id:null,
                apelido:'',
                cep:'',
                logradouro:'',
                complemento:'',
                bairro:'',
                localidade:'',
                uf:'',
                numero:'',
                principal:false
        }
        })
    }

    editarEndereco(endereco){
        this.state.endereco = Object.assign({},endereco);
        this.setState({
            view:FORM_VIEW,
        })

    }

    salvarEndereco(){

        this.validateForm()
        if(this.state.formisValid){
            this.setState({loading:true})

            ClienteService.salvarEndereco(this.state.endereco).then(()=>{
                this.loadEnderecos();
                alert('Endereço salvo');
            }).finally(()=>{
                this.setState({loading:true,view:LISTING_VIEW})
            })
        }


    }

    excluirEndereco(endereco){
        if(window.confirm('Realmente deseja excluir o endereço? Ação não pode ser desfeita')){
            this.setState({loading:true})
            ClienteService.excluirEndereco(endereco).then(()=>{

                alert('Endereço removido')
            }).finally(()=>{
                this.setState({loading:false})
                this.loadEnderecos();
            })
        }
    }


    validateForm(){
        this.setState({
            loading: true
        });
        this.state.fieldsWithError={}
        var fieldsToValidateList = Array.from(document.getElementsByClassName('form-field'))
        fieldsToValidateList.forEach(field=>{
            if(this.isFieldValid(field)===false){
                this.state.fieldsWithError[field.name] = true;
            }
        })

        this.state.formisValid = Object.keys(this.state.fieldsWithError).length===0;
        this.setState({
            loading: false
        });
    }

    isFieldValid(target){

        let result = true;
        let validationMap ={
            cpfCnpj:()=>{
                if(target.value.length===11){
                    result = validarCPF(target.value);
                }else if(target.value.length===14){
                    result = validarCNPJ(target.value);
                }else{
                    result = false;
                }
            },
            cep:()=>{
                result = target.value.replace(/\D/g,'').length===8  ;
            },
            dataNascimento:()=>{
                let dataNascimento = dayjs(target.value,'DD/MM/YYYY');
                result = dataNascimento.isValid()

            },
            password:()=>{
                result = target.value.length>=4  ;
            },
        }

        if(target.required){

            if((target.value==='' || !!target.value===false )){
                result = false;
            }else{
                validationMap[target.name] && validationMap[target.name]();
            }

        }

        return result

    }

    render() {

        return (
            <div className="product-area ptb-90">
                <div className="container">
                    {this.state.view===LISTING_VIEW && (
                        <>
                            <div className="row"  style={{marginBottom: 30 + 'px'}}>
                                <div className="col-sm-4 col-sm-offset-4">
                                    <div className="account-form">
                                        <ol className="breadcrumb">
                                            <li><Link to="/app/minha-conta">Minha conta</Link></li>
                                            <li>Meus endereços</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 clickable text-center text-success" onClick={this.novoEndereco}>

                                    <i className="fa fa-plus-circle fa-5x"/>
                                    <p>
                                        Adicionar novo endereço
                                    </p>

                                </div>

                                {
                                    this.state.enderecoList.map((endereco, index) => (
                                        <div className="col-md-4" key={index}>
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <span>
                                                        {endereco.apelido}
                                                    </span>
                                                </div>
                                                <div className="panel-body">
                                                    <p>{endereco.logradouro}, {endereco.numero}</p>
                                                    <p>Bairro: {endereco.bairro}, {endereco.localidade}-{endereco.uf}</p>
                                                    <p>cep:{endereco.cep}</p>
                                                    {endereco.principal?<span className="label label-success">Endereço principal</span>:null}
                                                </div>
                                                <div className="panel-footer">
                                                    <span style={{marginRight:'20px'}}>
                                                    <a className='clickable' onClick={event=>this.editarEndereco(endereco)} >editar</a>
                                                    </span>
                                                    <span>
                                                        <a className="clickable text-danger" onClick={event=>{this.excluirEndereco(endereco)}}>excluir</a>
                                                    </span>


                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }




                            </div>
                        </>
                    )}

                    {this.state.view===FORM_VIEW && (
                        <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                                <div className="account-form">
                                    <form >

                                        <fieldset  disabled={this.state.loading}>
                                            <h4 className="text-center mb-30">Endereço</h4>

                                            <div className={`col-md-6 form-group ${this.state.fieldsWithError['apelido']?'has-error':''}`}>
                                                <label className="">Identificação  <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" name="apelido"
                                                       onChange={this.handleEndereco} required
                                                       placeholder="Ex: casa, trabalho..." value={this.state.endereco.apelido} />
                                            </div>

                                            <div className={`col-md-6 form-group ${this.state.fieldsWithError['cep']?'has-error':''}`}>
                                                <label className="">CEP <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field"
                                                       name="cep" required
                                                       value={this.state.endereco.cep?maskCep(this.state.endereco.cep):''}
                                                       onBlur={this.searchCepEndereco}
                                                       onChange={(event)=> this.handleEndereco(event,maskCep)}
                                                       placeholder="" />
                                            </div>


                                            <div className={`col-md-8 form-group ${this.state.fieldsWithError['logradouro']?'has-error':''}`}>
                                                <label className="">Endereco <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field"
                                                       name="logradouro" onChange={this.handleEndereco} required
                                                       value={this.state.endereco.logradouro}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-4 form-group ${this.state.fieldsWithError['numero']?'has-error':''}`}>
                                                <label className="">Número <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       name="numero" onChange={this.handleEndereco}
                                                       value={this.state.endereco.numero}
                                                       placeholder="" />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label className="">Complemento</label>
                                                <input type="text" className="form-control input-lg form-field"
                                                       value={this.state.endereco.complemento}
                                                       name="complemento" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>

                                            <div className={`col-md-5 form-group ${this.state.fieldsWithError['bairro']?'has-error':''}`}>
                                                <label className="">Bairro <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.bairro}
                                                       name="bairro" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-5 form-group ${this.state.fieldsWithError['localidade']?'has-error':''}`}>
                                                <label className="">Localidade <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.localidade}
                                                       name="localidade" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-2 form-group ${this.state.fieldsWithError['uf']?'has-error':''}`}>
                                                <label className="">UF <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.uf}
                                                       name="uf" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>

                                            {this.state.loading?(
                                                <div className="mt-30 text-center">
                                                    <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                </div>
                                            ):''}

                                            <div className="col-md-12 form-group">
                                                <label className="">
                                                    <span style={{marginRight:'10px'}}>Endereço principal</span>
                                                    <input type="checkbox" className=" form-field"
                                                           value={this.state.endereco.principal}
                                                           name="principal" onChange={this.handleEndereco}
                                                           placeholder="" />
                                                </label>

                                            </div>

                                            <div className="col-md-12 form-group text-center">
                                                <button type='button' className="btn btn-block btn-round btn-base" onClick={this.salvarEndereco}>{this.state.endereco.id ? 'Atualizar endereço' : 'Adicionar endereço'}</button>
                                                <div style={{marginTop: 30 + 'px'}} className="text-center">
                                                    <a  className="clickable" onClick={event=> this.setState({view:LISTING_VIEW})} > cancelar</a>
                                                </div>
                                            </div>

                                        </fieldset>

                                    </form>

                                </div>
                            </div>
                        </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default MeusEnderecosComponent
